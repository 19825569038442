.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiButtonBase-root {
  display: flex !important;

  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  color: inherit;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
}
.MuiContainer-root {
  background-color: #164470 !important;
  color: white;
}
.MuiButtonBase-root {
  margin: 0 15px !important;
  padding: 6px 16px !important;
}
.MuiButtonBase-root p {
  padding: 0 10px !important;
}

.MuiButtonBase-root:hover,
.MuiButtonBase-root.Mui-selected {
  background-color: #275e92;
}

.matchTime,
.matchName,
.matchDescription,
.matchPresence,
.addOns  {
  width: 100%;
  text-align: center;
  clear:both;
  position:relative;
  float:left;
  padding: 5px 0;
  color: black !important;
}
.matchTime {
  font-weight: bold;
  font-size: 16px
}
.matchName {
  font-weight: bold;
  font-size:22px
}

.addOns table {
  margin: 0 auto;
}

.matchListContainer {
position: relative;
float: left;
width: 100%;
margin-bottom: 10px;
}

.matchListTime,
.matchListName,
.matchListRemark {
  width: 100%;
  text-align: center;
  clear:both;
  position:relative;
  float:left;
  color: black !important;
}

.matchListRemark {
  font-style: italic;
  font-weight: bold;
  font-size: 14px
}

.matchListTime {
  font-weight: bold;
  font-size: 14px
}
.matchListName {
  font-weight: bold;
  font-size:18px
}

.matchPresence ul {
  background-color:#16447017;   
  padding: 10px;
  margin: 0px;
  border-radius: 10%;
}

.matchPresence ul li{
  font-size: 14px;
  display: inline;
  padding: 4px;
  margin: 0;
  color: black !important;
}

.matchPresence ul li .circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 8px;
  background: #fff;
  color: #000;
  text-align: center;
  display: inline;
}

.matchPresence ul li .circle.present {
  background-color:#b8e1b8;
}
.matchPresence ul li .circle.off {
  background-color:#f15656;
}
.matchPresence ul li .circle.unknown {
  background-color:#cdcdea;
}

th.rotated-text {
  height: 80px;
  white-space: nowrap;
  padding: 0 !important;
}

th.rotated-text > div {
  transform:
      rotate(270deg);
  width:20px;
}

th.rotated-text > div > span {
  padding: 0;
  margin: 0;
  position: relative;
  left: -30px;
}

.presentTable {
  width: 100%;
}

.presentTable tr td {
  padding:0;
  margin: 0;
}

.presentTable tr td .present {
  padding: 5px;
  text-align:center;
}

.present.present_null {
  background-color: rgb(221, 183, 111);
}
.present.present_1 {
  background-color: #b8e1b8;
}
.present.present_0 {
  background-color: #f15656;
}

.matchPlayerBlock {
  clear: both;
  float: left;
  width: 100%;
  height: auto;
  text-align: center;
}

.matchPlayerList {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
}

.matchPlayerList li {
  margin: 5px 0;
}

.presence {
  margin-bottom: -5px;
}
.presence.unknown {
  color:#cdcdea;
}

.presence.off {
  color:#f15656;
}
.presence.present {
  color:#b8e1b8;
}

.wrapper_container {
  margin-bottom: 20px;
}

.wrapper{
  display: inline-flex;
  height: 35px;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px 0;
}
.wrapper .option{
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5px 0 0;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 22px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.wrapper .option.selected.unknown {
  background-color: #cdcdea;
  color: black;
}

.wrapper .option.selected.yes {
  background-color: #b8e1b8;
  color: black;
}

.wrapper .option.selected.no {
  background-color: #f15656;
  color: black;
}

.wrapper .option.selected.unknown span,
.wrapper .option.selected.yes span,
.wrapper .option.selected.no span{
  color: black;
}
.wrapper .submitbox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
}

.wrapper input[type="radio"]{
  display: none;
}

.wrapper .option span{
  font-size: 14px;
  color: #808080;
}

.reasonBox {
  padding: 5px;
  font-size: 14px;
}

.reasonBox label {
  width: 40%;
  padding-right: 10px;
}

.reasonBox input {
  height: 30px;
  width: 80%;
  border-radius: 5px;
  border: 2px solid lightgrey;
}

  .break {
      clear: both;
      position: relative;
      float: left;
      width: 100%;
      height: 10px;
      border-bottom: solid 1px silver;
}

.table tr td {
  margin: 0;
  padding: 3px 5px;
  border:solid 1px silver
}

.standingContainer.bold td {
  font-weight:bold;
  background-color: #c5dbeb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
